
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import MasterLayout from '@/components/MasterLayout.vue';
import PostHeader from '@/components/NonLoggedInComponents/PostHeader.vue';
import PostIntro from '@/components/NonLoggedInComponents/PostIntro.vue';
import PostInfoHeader from '@/components/NonLoggedInComponents/PostInfoHeader.vue';
import PostInfoImg from '@/components/NonLoggedInComponents/PostInfoImg.vue';
import PostContact from '@/components/NonLoggedInComponents/PostContact.vue';
import PostSpacer from '@/components/NonLoggedInComponents/PostSpacer.vue';
import PostCard from '@/components/NonLoggedInComponents/PostCard.vue';
import J1LGetStarted from '@/components/NonLoggedInComponents/J1LGetStarted.vue';
@Component({
  components: {
    MasterLayout,
    PostHeader,
    PostIntro,
    PostInfoHeader,
    PostInfoImg,
    PostContact,
    PostSpacer,
    PostCard,
    J1LGetStarted
  }
})
export default class SkinAndBeautyLabels extends Vue {
  $refs!: {
    DefaultActions: HTMLFormElement;
  };
  // metaInfo(): any {
  //   return {
  //     meta: [
  //       {
  //         name: 'description',
  //         content:
  //           'Just 1 Label is a digital label printing company. We provide online label printing services for companies in various industries nationwide. Register for free today.'
  //       }
  //     ],
  //     title: 'Digital Label Printing, Online Label Printing Services'
  //   };
  // }
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
