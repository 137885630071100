
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import PostInfoHeader from '@/components/NonLoggedInComponents/PostInfoHeader.vue';
import PostCard from '@/components/NonLoggedInComponents/PostCard.vue';
@Component({
  components: {
    PostInfoHeader,
    PostCard
  }
})
export default class J1LGetStarted extends Vue {
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
